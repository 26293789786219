import React from 'react';
import './About.scss';

import { IonContent, IonPage, IonIcon, IonItemGroup, IonItem, IonList, IonListHeader, IonButton, IonGrid, IonCol, IonRow } from '@ionic/react';
import { copy } from 'ionicons/icons';

const About: React.FC = () => {

  return (
    <IonPage>
      <IonContent>
        <IonItemGroup class="form-border-all">
          <IonGrid>
            <IonRow class="ion-justify-content-center">
              <IonCol size-xs="11" size-sm="9" size-md="10" size-lg="10" size-xl="9">
                <IonRow>
                <p className='about-details'>Centripet is a Software Engineering and IT Services Company. Centripet aims to be secure and effienct as possible in it's delivery. We engage in a wide range of sectors including but not limited to Industry, Finance, Healthcare, Ecommerce, Agriculture and Defense.
                    We are an equal opportunity employment company. You can reach us through our contact page or we may be reached by phone (314) 262 9559 or email sales@centripet.com
                </p>
                </IonRow>
                <IonRow><p className='about-details'>Mission: To pursue solutions through technological breakthroughs</p> </IonRow>
                <IonRow><p className='about-details'>Vision: Empowering by innovation</p></IonRow>
              </IonCol>
            </IonRow>  
          </IonGrid>       
        </IonItemGroup>
      </IonContent>
    </IonPage>
  );
};

export default About;
