import React, { useState, useEffect, useMemo, useRef } from 'react';
import './Services.scss';
import { makeStyles } from '@material-ui/core/styles';
//import IndustrialImg from '../../../public/assets/icon/industril.jpg'

//import MaUTable from "@material-ui/core/Table";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import { IonPage, IonContent, IonInput, IonItem, IonLabel, IonButton, IonItemGroup,
         IonModal, IonItemDivider, IonSelect, IonSelectOption,
         IonGrid, IonCol, IonRow, 
         IonIcon,
         IonCard,
         IonCardHeader,
         IonCardTitle,
         IonCardSubtitle,
         IonCardContent,
         IonList,
         IonThumbnail,
         IonHeader,
         IonToolbar,
         IonTitle,
         IonButtons,
         IonMenuButton} from '@ionic/react';
import { Box } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import fetchDataTest from '../../api/API';
import { IonReactRouter } from '@ionic/react-router';
import { Link, useHistory } from 'react-router-dom';

const Services: React.FC = () => {

  //set service accordion toggle states
 const [serviceCloud, setServiceCloud] = useState(false) as any;
 const [serviceDatabase, setServiceDatabase] = useState(false) as any;
 const [serviceScalingOpt, setserviceScalingOpt] = useState(false);
 const [serviceSoftwareArchNDev, setServiceSoftwareArchNDev] = useState(false);
 const [serviceDevopsNCicd, setServiceDevopsNCicd] = useState(false);

 const [serviceCloudHover, setServiceCloudHover] = useState(false) as any;
 const [serviceDatabaseHover, setServiceDatabaseHover] = useState(false) as any;
 const [serviceScalingOptHover, setServiceScalingOptHover] = useState(false) as any;
 const [serviceSoftwareArchNDevHover, setServiceSoftwareArchNDevHover] = useState(false) as any;
 const [serviceDevopsNCicdHover, setServiceDevopsNCicdHover] = useState(false) as any;
 

 //the expand contract +- icons based on the accordion expand collapse change
 const [customServiceCloudExpandIcon, setCustomServiceCloudExpandIcon] = useState("") as any;
 const [customServiceDatabaseExpandIcon, setCustomServiceDatabaseExpandIcon] = useState("") as any;
 const [customServiceScalingOptExpandIcon, setCustomServiceScalingOptExpandIcon] = useState("") as any;
 const [customServiceSoftwareArchNDevExpandIcon, setCustomServiceSoftwareArchNDevExpandIcon] = useState("") as any;
 const [customServiceServiceDevopsNCicdExpandIcon, setCustomServiceServiceDevopsNCicdExpandIcon] = useState("") as any;

 const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
 
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },

    mainMessage: {
      textAlign: 'left',
      fontSize: 'large',
      fontWeight: 'lighter'

    },

    accordionSummaryStyles : {
      fontSize: 'large',
      fontWeight: 'bold',
      '&:hover': {
        color: "#0095fff2",
    }
  },

  accordionSummaryStylesExpanded : {
    fontSize: 'large',
    fontWeight: 'bold',
   color: "#0095fff2",
  },
    
  areaStyles : {
    display: 'block',
    cursor: 'pointer',
    backgroundColor: 'red'
  },

  }));
 
  //set the custom accordion +- expand colapse icon funtional component
  const CustomServiceExpandIcon = (serviceSector: any) => {
    return (
      <Box 
      >
      { (serviceSector) ? <div className="expandIconWrapper">-</div> : <div className="collapsIconWrapper">+</div>}
      </Box>
    );
  };

  //set the accordion onclick toggle flags
  const toggleServicecloudAccordion = () => {setServiceCloud(!serviceCloud); return; };
  const toggleServiceDatabaseAccordion = () => {setServiceDatabase(!serviceDatabase); return; };
  const toggleServiceScalingOptAccordion = () => {setserviceScalingOpt(!serviceScalingOpt); return; };
  const toggleServiceSoftwareArchNDevAccordion = () => {setServiceSoftwareArchNDev(!serviceSoftwareArchNDev); return; };
  const toggleServiceDevopsNCicdAccordion = () => {setServiceDevopsNCicd(!serviceDevopsNCicd); return; };

  //set the image area section hover flags to highlight the accordion service sections
  const toggleServicecloudAccordionHover = () => {setServiceCloudHover(!serviceCloudHover); return; };
  const toggleServiceDatabaseAccordionHover = () => {setServiceDatabaseHover(!serviceDatabaseHover); return; };
  const toggleServiceScalingOptAccordionHover = () => {setServiceScalingOptHover(!serviceScalingOptHover); return; };
  const toggleServiceSoftwareArchNDevAccordionHover = () => {setServiceSoftwareArchNDevHover(!serviceSoftwareArchNDevHover); return; };
  const toggleServiceDevopsNCicdAccordionHover = () => {setServiceDevopsNCicdHover(!serviceDevopsNCicdHover); return; };

  const classes = useStyles();
  //image with custom map clickable areas
  const CustomImgMapArea = () => {
    return (
      <div className={classes.root}>
      <img src='assets/Centripet_Services.jpg' height="100%" width="100%" className="centripet-main-img" alt="My Image" useMap="#overButtonMap"/>
      <map name="overButtonMap">
        <area onClick={toggleServicecloudAccordion} onMouseEnter={toggleServicecloudAccordionHover} onMouseLeave={toggleServicecloudAccordionHover} className={classes.areaStyles} title={serviceCloudHover ? 'API Cloud & Management' : ''} shape="rect" coords="34,44, 234, 144" alt="Page1"/>
        <area onClick={toggleServiceDatabaseAccordion} onMouseEnter={toggleServiceDatabaseAccordionHover} onMouseLeave={toggleServiceDatabaseAccordionHover} className={classes.areaStyles} title={serviceDatabaseHover ? 'Database Management' : ''} shape="rect" coords="500,44,700, 144"  alt="Page1"/>
        <area onClick={toggleServiceScalingOptAccordion} onMouseEnter={toggleServiceScalingOptAccordionHover} onMouseLeave={toggleServiceScalingOptAccordionHover} className={classes.areaStyles} title={serviceScalingOptHover ? 'Scaling & Optimization' : ''} shape="rect" coords="34,344,234, 444"  alt="Page1"/>
        <area onClick={toggleServiceSoftwareArchNDevAccordion} onMouseEnter={toggleServiceSoftwareArchNDevAccordionHover} onMouseLeave={toggleServiceSoftwareArchNDevAccordionHover} className={classes.areaStyles} title={serviceSoftwareArchNDevHover ? 'Software Architechture & Dev' : ''} shape="rect" coords="500,344,700, 444"  alt="Page1"/>
        <area onClick={toggleServiceDevopsNCicdAccordion} onMouseEnter={toggleServiceDevopsNCicdAccordionHover} onMouseLeave={toggleServiceDevopsNCicdAccordionHover} className={classes.areaStyles} title={serviceDevopsNCicdHover ? 'Devops & CICD' : ''} shape="rect" coords="260,444,380, 564"  alt="Page1"/>
      </map> 
    </div>
    );
  };

  const ServiceSectionDesktop = () => {
    return (
      <>
     <IonCol>
          <CustomImgMapArea />
          </IonCol>
          <IonCol>
              <div className={classes.mainMessage}>
                  <Accordion expanded={serviceCloud} onChange={handleServiceCloudChange}>
                    <AccordionSummary className={(serviceCloud) || (serviceCloudHover) ? classes.accordionSummaryStylesExpanded : classes.accordionSummaryStyles} expandIcon={customServiceCloudExpandIcon}>API and Cloud management</AccordionSummary>
                    <AccordionDetails>
                      We manage cloud and cloud API FAAS. This includes AWS, Azure, and Google cloud
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={serviceDatabase} onChange={handleServiceDatabaseChange}>
                    <AccordionSummary className={(serviceDatabase) || (serviceDatabaseHover) ? classes.accordionSummaryStylesExpanded : classes.accordionSummaryStyles} expandIcon={customServiceDatabaseExpandIcon}>Database engineering, maintenance, and optimization</AccordionSummary>
                    <AccordionDetails>
                      We design, develop and optimize data bases. Data consistency, efficency and security are cornerstone to 
                      our data base management. 
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={serviceScalingOpt} onChange={handleServiceScalingOptChange}>
                    <AccordionSummary className={(serviceScalingOpt) || (serviceScalingOptHover) ? classes.accordionSummaryStylesExpanded : classes.accordionSummaryStyles} expandIcon={customServiceScalingOptExpandIcon}>Scaling and Optimization</AccordionSummary>
                    <AccordionDetails>
                      Scalability is central to our process. We adhere to scalable models at every level of the development process.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={serviceSoftwareArchNDev} onChange={handleServiceSoftwareArchNDevChange}>
                    <AccordionSummary className={(serviceSoftwareArchNDev) || (serviceSoftwareArchNDevHover) ? classes.accordionSummaryStylesExpanded : classes.accordionSummaryStyles} expandIcon={customServiceSoftwareArchNDevExpandIcon}>Software Architecture and Development</AccordionSummary>
                    <AccordionDetails>
                      We develop secure and optimized software from ground up. Effeciency is at 
                      the center of our development approaches. We maximize productivity throughout the life cycle from
                      planning, designing, implementation, testing, to deployment.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={serviceDevopsNCicd} onChange={handleServiceDevopsNCicdChange}>
                    <AccordionSummary className={(serviceDevopsNCicd) || (serviceDevopsNCicdHover) ? classes.accordionSummaryStylesExpanded : classes.accordionSummaryStyles} expandIcon={customServiceServiceDevopsNCicdExpandIcon}>Devops and CICD</AccordionSummary>
                    <AccordionDetails>
                      We provide devops and CICD for cloud and on prem pipelines. Security, stabilty and acceleration are focal 
                      throughout our deployment process.
                    </AccordionDetails>
                  </Accordion>    
            </div>
          </IonCol>
      </>
    );
  };

  const history = useHistory();

  const backClickHandler = ()  => { setServiceCloud(!serviceCloud); return; };

  const forwardClickHandler = (e:any, to:any, pageSection: any)  => { 
    //console.log('so whats clicko ====');
    //console.log(to);
    e.preventDefault(); 
     history.push({
         pathname: to,
         state: pageSection 
    });
     
    return; 
  };

  const ServiceSectionMobile = () => {
    return (
      <>
     <IonCol>

         <IonHeader className="ion-no-border" translucent>
            <IonToolbar>
              <IonTitle class="no-border">Services</IonTitle>
            </IonToolbar>
          </IonHeader>

      <IonReactRouter>
        <IonList no-lines>
          <IonItem class="no-border">
            <IonThumbnail class="thumbnail-style" slot="start">
              <IonButton fill="clear" class="button-style" color="#051a0d" onClick={(e) => forwardClickHandler(e,"/serviceDetails","APInCloud")}>
                <img alt="centripet API and cloid management" src="assets/Individual_services/cloud.png" />
                </IonButton>  
            </IonThumbnail>
          <IonLabel class="service-label-mobile-style">API and Cloud management</IonLabel>
          </IonItem>

          <IonItem class="no-border">
          <IonThumbnail class="thumbnail-style" slot="start">
              <IonButton fill="clear" class="button-style" color="#051a0d" onClick={(e) => forwardClickHandler(e,"/serviceDetails","DatabaseEng")}>
                <img alt="centripet database service" src="assets/Individual_services/databases.png" />
                </IonButton>  
            </IonThumbnail>
          <IonLabel class="service-label-mobile-style">Database engineering, maintenance</IonLabel>
          </IonItem>

          <IonItem class="no-border">
          <IonThumbnail class="thumbnail-style" slot="start">
              <IonButton fill="clear" class="button-style" color="#051a0d" onClick={(e) => forwardClickHandler(e,"/serviceDetails","ScalingOpt")}>
                <img alt="centripet scaling optimization" src="assets/Individual_services/scaling.png" />
                </IonButton>  
            </IonThumbnail>
          <IonLabel class="service-label-mobile-style">Scaling and Optimization</IonLabel>
          </IonItem>

          <IonItem class="no-border">
          <IonThumbnail class="thumbnail-style" slot="start">
              <IonButton fill="clear" class="button-style" color="#051a0d" onClick={(e) => forwardClickHandler(e,"/serviceDetails","SoftwareArcnDev")}>
                <img alt="centripet software architechture and development" src="assets/Individual_services/software_arch_n_dev.png" />
                </IonButton>  
            </IonThumbnail>
          <IonLabel class="service-label-mobile-style">Software Architecture and Development</IonLabel>
          </IonItem>

          <IonItem class="no-border">
          <IonThumbnail class="thumbnail-style" slot="start">
              <IonButton fill="clear" class="button-style" color="#051a0d" onClick={(e) => forwardClickHandler(e,"/serviceDetails","DevopsnCICD")}>
                <img alt="centripet Devops and CICD" src="assets/Individual_services/devops_n_cicd.png" />
                </IonButton>  
            </IonThumbnail>
          <IonLabel class="service-label-mobile-style">Devops and CICD</IonLabel>
          </IonItem>

        </IonList>
        </IonReactRouter>
    </IonCol>
      </>
    );
  };

  //handle the accordion tab changes
  const handleServiceCloudChange = ()  => { setServiceCloud(!serviceCloud); return; };
  const handleServiceDatabaseChange = ()  => { setServiceDatabase(!serviceDatabase); return; };
  const handleServiceScalingOptChange = ()  => { setserviceScalingOpt(!serviceScalingOpt); return; };
  const handleServiceSoftwareArchNDevChange = ()  => { setServiceSoftwareArchNDev(!serviceSoftwareArchNDev); return; };
  const handleServiceDevopsNCicdChange = ()  => { setServiceDevopsNCicd(!serviceDevopsNCicd); return; };

  //set the expand contract +- icons based on the accordion expand colapse change
  useMemo(() => {
     setCustomServiceCloudExpandIcon(CustomServiceExpandIcon(serviceCloud));
     //poc for API proxied call
      //const Tdata = fetchDataTest(); //example API fetch call
      //console.log(Tdata);
   }, [serviceCloud]);

  useMemo(() => { setCustomServiceDatabaseExpandIcon(CustomServiceExpandIcon(serviceDatabase)); }, [serviceDatabase]);
  useMemo(() => { setCustomServiceScalingOptExpandIcon(CustomServiceExpandIcon(serviceScalingOpt)); }, [serviceScalingOpt]);
  useMemo(() => { setCustomServiceSoftwareArchNDevExpandIcon(CustomServiceExpandIcon(serviceSoftwareArchNDev)); }, [serviceSoftwareArchNDev]);
  useMemo(() => { setCustomServiceServiceDevopsNCicdExpandIcon(CustomServiceExpandIcon(serviceDevopsNCicd)); }, [serviceDevopsNCicd]);

  return (
      <IonPage>
        <IonContent>
          <IonItemGroup class="form-border-all">
          <IonGrid>
          <IonRow class="ion-justify-content-center">
          <IonCol size-xs="13" size-sm="9" size-md="10" size-lg="10" size-xl="9">
            <IonRow>
              {!isMobile ? <ServiceSectionDesktop /> : <ServiceSectionMobile />}
            </IonRow>
            </IonCol>

            </IonRow>
              </IonGrid>
          </IonItemGroup>
        </IonContent>
      </IonPage>
    );
  };

export default Services;
