import React from 'react';
import { IonContent, IonPage, IonIcon, IonItemGroup, IonItem, IonList, IonListHeader, IonButton, IonGrid, IonCol, IonRow, IonHeader, IonToolbar, IonTitle, IonButtons, IonLabel } from '@ionic/react';
import { copy } from 'ionicons/icons';
import './ServiceDetails.scss';
import { Link, useHistory } from 'react-router-dom';


const ServiceDetails: React.FC = (props: any) => {

const history = useHistory();

const backClickHandler = (e:any,to:any)  => { 
  //console.log('so whats clicko ====');
  //console.log(to);
  e.preventDefault(); 
   history.push({
       pathname: to,
       state: '' 
  });
   
  return; 
};

const DetailsArea = (props: any) => {
  return (
       <>
      <IonHeader className="ion-no-border" translucent>
      <IonToolbar>
        <IonTitle>Service Details</IonTitle>
        <IonButtons slot="start">
          <IonButton class="back-button" fill="clear" onClick={(e) => backClickHandler(e,"/services")}>&lt;Back</IonButton>
        </IonButtons>
      </IonToolbar>
      </IonHeader>
      <IonLabel class="sub-title">{props.subTitle}</IonLabel><p className='service-details'>{props.mainDetail}</p>
      </>
    
  );
};

const { state } = props.location
  return (
    <IonPage>

      <IonContent>
        <IonItemGroup class="form-border-all">
          <IonGrid>
            <IonRow class="ion-justify-content-center">
              <IonCol size-xs="12" size-sm="9" size-md="10" size-lg="10" size-xl="9">
                {(state=='APInCloud') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={"API and Cloud management"} mainDetail={"We manage cloud and cloud API FAAS. This includes AWS, Azure, and Google cloud."} />
                </IonRow> :
                (state=='DatabaseEng') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={"Database engineering, maintenance"} mainDetail={"We design, develop and optimize data bases. Data consistency, efficency and security are cornerstone to our data base management."} />
                </IonRow>:
                (state=='ScalingOpt') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={"Scaling and Optimization"} mainDetail={" Scalability is central to our process. We adhere to scalable models at every level of the development process."} />
                </IonRow> :
                (state=='SoftwareArcnDev') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={"Software Architecture and Development"} mainDetail="We develop secure and optimized software from ground up. Effeciency is at
                      the center of our development approaches. We maximize productivity throughout the life cycle from
                      planning, designing, implementation, testing, to deployment." />
                </IonRow> :
                (state=='DevopsnCICD') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={"Devops and CICD"} mainDetail="We provide devops and CICD for cloud and on prem pipelines. Security, stabilty and acceleration are focal 
                      throughout our deployment process." />
                </IonRow> :
                <IonRow>
                  <p className='service-details'>Service Details</p>
                </IonRow>
                }
              </IonCol>
            </IonRow>  
          </IonGrid>       
        </IonItemGroup>
      </IonContent>
    </IonPage>
  );
};

export default ServiceDetails;
